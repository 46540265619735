.formButton{
    font-size:15px !important;
    border-radius:1rem !important;
    padding:0.3rem 1.5rem 0.4rem 1.5rem !important;
    background-color:transparent;
    color:#23a0e9 ;
    margin-top:15px;
    float:right;
    max-width:130px;
    justify-content:center !important;
}

.formButton:hover{
    background-image:linear-gradient(to right, rgba(255,255,255)) !important;
}

.formButton1{
    font-size:13px !important;
    border-radius:1rem !important;
    padding:0.3rem 1.5rem 0.4rem 1.5rem !important;
    background-color:transparent;
    color:#23a0e9 ;
    margin-top:15px;
    float:right;
    max-width:140px;
    text-align:center;
    justify-content:center !important;
}

.formButton1:hover{
    background-image:linear-gradient(to right, rgba(255,255,255)) !important;
}








.formcard{
    /* background-color: white; */
    border-radius: 11px !important;
    max-width:27rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10rem
}

.formLabel{
    color:rgb(126, 126, 126) !important;
    margin-top:30px !important;
}

.logintitle{
    font-size: 23px;
    font-weight: 800;
    margin-top: 0px;
}
.copyRightText{
    font-size:14px;
    font-family:'Montserrat';
    margin-top:7px;
    margin-left: auto;
    margin-right: auto;
}
.mt30{
    margin-top:30px;
}
.mt15{
    margin-top:15px;
}